import React, { useCallback, useReducer, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { QueryFunctionOptions } from '@apollo/client/react';
import { PasskeyOnboardingLocalStorageFacade } from '@app/features/passkey-onboarding/PasskeyOnboardingLocalStorageFacade';
import { shouldShowPasskeyOnboarding } from '@app/features/passkey-onboarding/utils';

import { AuthResult, SubmitWizardStepParams } from '@app/queryTyping';

import { WizardStepCard, WizardStepSizes } from '@app/common/components/configurable-wizards/WizardStepCard';
import { WizardStepMediator } from '@app/common/components/configurable-wizards/WizardStepMediator';
import {
  DEFAULT_WIZARD_STATE,
  setWizardStepAlertMessage,
  setWizardStepFieldsErrors,
  setWizardStepGenericErrors,
  setWizardStepLoading,
  setWizardStepState,
  setWizardStepTerminationStepAction,
  setWizardStepUnhandledErrors,
  stepStateReducer,
  WizardStepSubmitHandler,
} from '@app/common/configurable-wizards';
import { setWizardStepData } from '@app/common/configurable-wizards/state/useWizardStepData';
import { setWizardStepSucceedLoaded } from '@app/common/configurable-wizards/state/useWizardStepSucceedLoaded';
import { WizardStepStateDispatchProvider } from '@app/common/configurable-wizards/state/WizardStateDispatchContext';
import { isNextStep, isWizardStepsInconsistencyError } from '@app/common/configurable-wizards/utils/assertions';
import { WizardStepStateProvider } from '@app/common/configurable-wizards/WizardStateStateContext';
import { getUserErrors } from '@app/common/errors/getGraphQLUserError';
import { useDocumentTitle } from '@app/common/utils/hooks/useDocumentTitle';
import { isHostedInFrame } from '@app/common/utils/isHostedInFrame/isHostedInFrame';
import { getServerErrors } from '@app/common/utils/serverValidation';

import { reconnectWebSocket } from '@app/core/apolloClient';
import { setAuthenticationToken } from '@app/core/authentication/authenticationToken';
import { tetheredLoginRedirect } from '@app/core/authentication/tetheredLogin/tetheredLoginRedirect';
import { setUserIdHash } from '@app/core/authentication/userIdHash';
import { HistoryState } from '@app/core/components/Application/usePagePath';
import { findForcePasswordResetError } from '@app/core/components/ForcePasswordReset/forcePasswordResetApolloLinks';
import { findReCaptchaError } from '@app/core/components/ReCaptcha/reCaptchaApolloLink';
import { isTetheredLogin } from '@app/core/components/TetheredLoginApplication';
import {
  isTriggeredEventCanceledApolloError,
} from '@app/core/components/TriggeredEvents/TriggeredEventCanceledApolloError';
import { PageURL } from '@app/core/widgets/pages';

import { LoginLocalSettings } from '../LoginLocalSettings';
import { LoginWizardStepRouterParams } from '../types';

import { WizardSteps as RetrievalWizardSteps } from '../../user-retrieval/constants';
import { useUserLoginTranslation } from '../hooks/useUserLoginTranslation';
import { useGetLoginStepQuery } from './queries/queryTyping/get-wizard-step';
import { useMovePreviousLoginStepMutation } from './queries/queryTyping/move-to-previous-step';
import { usePostLoginStepMutation } from './queries/queryTyping/post-wizard-step';
import { LoginMode } from './steps/UsernamePassword/types';

import { Constants, LOGIN_MODE_KEY, WizardSteps } from './constants';
import { isAuthResult } from './resultTypeCheck';
import { steps } from './steps';

export const LoginWizard: React.FunctionComponent = () => {
  const history = useHistory();
  const { t } = useUserLoginTranslation();
  // eslint-disable-next-line i18next/no-literal-string
  const stepId = useParams<LoginWizardStepRouterParams>().stepId || '';
  const [state, dispatch] = useReducer(stepStateReducer, DEFAULT_WIZARD_STATE);
  const tetheredMode = isTetheredLogin(window.location.pathname);
  const historyState = history.location.state as HistoryState;

  const [isLoginWithPasskey, setLoginWithPasskey] = useState(false);

  // set current step name to the document title
  useDocumentTitle(
    state.name ? t(
      'login.document-step-title|Document title of the login steps',
      '{{stepName}} | User Login',
      { stepName: state.name },
    ) : t(
      'login.document-general-title|Document title of the login',
      'User Login',
    ),
    false,
    true,
  );

  const navigateNext = (path: string, reload?: boolean, hs?: Partial<HistoryState>) => {
    if (tetheredMode && isHostedInFrame()) {
      tetheredLoginRedirect(path);
    } else if (reload) {
      window.location.replace(path);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      hs
        ? history.replace(path, hs)
        : history.replace(path);
    }
  };

  const processSuccessResult = useCallback(({
    userEligibleForPasskey,
    userUidHash,
  }: Pick<AuthResult, 'userEligibleForPasskey' | 'userUidHash'>) => {
    const nextUrl = historyState?.to || historyState?.from;
    const firstPathSegment = `/${nextUrl?.split('/')[1]}`;

    const isValidNextUrl = nextUrl && Object.values(PageURL).includes(firstPathSegment as PageURL);

    if (!shouldShowPasskeyOnboarding({
      isLoginWithPasskey,
      isPasskeyEnabled: LoginLocalSettings.instance.isPasskeyEnabled,
      passkeyOnboardingPresentingUserData: PasskeyOnboardingLocalStorageFacade.getData(userUidHash),
      userEligibleForPasskey,
    })) {
      navigateNext(isValidNextUrl ? nextUrl : PageURL.BANK_ACCOUNT);
    } else {
      navigateNext(PageURL.PASSKEY_ONBOARDING, false, {
        to: nextUrl ?? PageURL.BANK_ACCOUNT,
      });
    }
  }, [isLoginWithPasskey]);

  /**
   * Handles wizard request/response errors
   */
  const onApolloError: QueryFunctionOptions['onError'] = (wizardError) => {
    dispatch(setWizardStepLoading(false));

    const recaptchaError = findReCaptchaError(wizardError.graphQLErrors);
    if (recaptchaError) {
      // Reaction on reCaptcha error to indicate that exception occured
      window.postMessage(recaptchaError, window.location.origin);
      return;
    }

    if (findForcePasswordResetError(wizardError.graphQLErrors)) {
      navigateNext(`${PageURL.USER_RETRIEVAL}/${RetrievalWizardSteps.ForcePasswordReset}`);
      return;
    }

    const displayErrors = getUserErrors(wizardError);
    if (displayErrors) {
      const urlParams = new URLSearchParams({
        // eslint-disable-next-line i18next/no-literal-string
        isSuccess: 'false',
        message: encodeURIComponent(displayErrors.join()),
      });
      const location = tetheredMode ? window.parent.location : window.location;

      location.replace(
        // eslint-disable-next-line max-len
        `${window.location.origin}${PageURL.USER_LOGIN}/${WizardSteps.LoginResult}?${urlParams.toString()}`,
      );
    } else if (!isTriggeredEventCanceledApolloError(wizardError)) {
      dispatch(setWizardStepUnhandledErrors([wizardError]));
    }
  };

  useGetLoginStepQuery(
    {
      variables: {
        stateParams: {
          stepId,
        },
      },
      // cache should be turned off cause the backend controls the wizard step state
      // and request by request this state my be different, and set of fields and its values may be changed
      fetchPolicy: 'no-cache',
      onCompleted: ({ loginStep }) => {
        setWizardStepSucceedLoaded(dispatch);
        setWizardStepData(dispatch, loginStep);
      },
      onError: (error) => {
        // start workflow from the first step again if got step inconsistency
        if (isWizardStepsInconsistencyError(error)) {
          history.replace(PageURL.USER_LOGIN);
        } else {
          onApolloError(error);
        }
      },
    },
  );

  const [submit] = usePostLoginStepMutation(
    {
      onCompleted: (response) => {
        dispatch(setWizardStepLoading(false));
        const result = response.loginStep!;

        if (isNextStep(result)) {
          // show validation errors
          if (result.errors.length) {
            const { genericErrors, fieldErrors } = getServerErrors(result.errors);

            if (genericErrors) {
              dispatch(setWizardStepGenericErrors(genericErrors));
            }

            if (fieldErrors) {
              dispatch(setWizardStepFieldsErrors(fieldErrors));
            }

            dispatch(setWizardStepLoading(false));
          } else if (state.isTermination && !result.nextStepId) {
            state.terminationStepAction?.();
          } else {
            // reset state before moving forward
            dispatch(setWizardStepState(DEFAULT_WIZARD_STATE));

            // go to next wizard step
            const nextStepUrl = `${PageURL.USER_LOGIN}/${result.nextStepId}`;
            navigateNext(nextStepUrl);
          }
        } else if (isAuthResult(result)) {
          // perform success login
          dispatch(setWizardStepAlertMessage({
            content: t('login.success.message|Login success message', 'Successful login'),
            type: 'success',
          }));

          setAuthenticationToken(result.userAuthToken!);
          setUserIdHash(result.userUidHash);
          reconnectWebSocket();

          processSuccessResult({
            userEligibleForPasskey: result.userEligibleForPasskey,
            userUidHash: result.userUidHash,
          });
        }
      },
      onError: onApolloError,
    },
  );

  const submitStep = useCallback<WizardStepSubmitHandler>(async (
    { data, setTerminationStepAction },
  ) => {
    dispatch(setWizardStepTerminationStepAction(setTerminationStepAction));
    dispatch(setWizardStepAlertMessage(null));

    if (state.id === WizardSteps.UserName) {
      const loginModeField = data.find(({ fieldId }) => fieldId === LOGIN_MODE_KEY);
      const loginMode = loginModeField?.value;
      const isPasskeyLogin = loginMode && loginMode === LoginMode.passkey;
      if (isPasskeyLogin) {
        setLoginWithPasskey(true);
      }
    }
    dispatch(setWizardStepLoading(true));

    submit({
      variables: {
        stepParams: {
          stepId,
          isTerminationStep: state.isTermination,
          stepData: data as SubmitWizardStepParams['stepData'],
        },
      },
    });
  }, [dispatch, submit, stepId, state.isTermination, state.id]);

  const [back] = useMovePreviousLoginStepMutation({
    onCompleted: ({ movePreviousLoginStep }) => {
      dispatch(setWizardStepLoading(false));

      const result = movePreviousLoginStep;

      if (result.errors.length) {
        const { genericErrors, fieldErrors } = getServerErrors(result.errors);

        if (genericErrors) {
          dispatch(setWizardStepGenericErrors(genericErrors));
        }

        if (fieldErrors) {
          dispatch(setWizardStepFieldsErrors(fieldErrors));
        }
      } else {
        // reset state before moving back
        dispatch(setWizardStepState(DEFAULT_WIZARD_STATE));
        // navigate to the prev wizard step
        history.replace(
          `${PageURL.USER_LOGIN}/${result.currentStepId}`,
        );
      }
    },
    onError: onApolloError,
  });

  const redirectBack = useCallback(() => {
    if (state.allowBackRedirect) {
      dispatch(setWizardStepLoading(true));
      back();
    }
  }, [state.allowBackRedirect, back, dispatch]);

  const redirectToStart = useCallback(() => {
    history.replace(PageURL.USER_LOGIN);
  }, [dispatch, history]);

  const getStepSize = (step?: string | null): WizardStepSizes => {
    if (tetheredMode) {
      // eslint-disable-next-line i18next/no-literal-string
      return 'full';
    }

    if (step === WizardSteps.EmailVerification) {
      // eslint-disable-next-line i18next/no-literal-string
      return 'large';
    }
    // eslint-disable-next-line i18next/no-literal-string
    return 'small';
  };

  return (
    <WizardStepStateDispatchProvider value={dispatch}>
      <WizardStepStateProvider value={state}>
        <WizardStepCard size={getStepSize(state.id)} loading={state.loading}>
          <WizardStepMediator
            steps={steps}
            wizardStepProps={{
              onSubmit: submitStep,
              onBack: redirectBack,
              onCancel: redirectToStart,
              wizard: Constants.WIZARD_NAME,
            }}
          />
        </WizardStepCard>
      </WizardStepStateProvider>
    </WizardStepStateDispatchProvider>
  );
};
